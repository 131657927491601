var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { on: { click: _vm.handleGoToDetails } }, [
    _vm._v(_vm._s(_vm.params.value))
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }