import { getOptionsConfigBySelectedApplicationTypes } from "../ymm-search/helpers";
import { FIELDS, INITIAL_FIELDS_CONFIG, EMPTY_ITEM } from "./constants";
import {
    getApplicationTypesOptionsFromResponse,
    getOptionsFromResponse,
    resetField,
    getResultField,
} from "./helpers";

import { isGPI } from "../partFinderCorporate.helpers";

const VALUE_PROPERTY = "id";
const PLURAL_VALUE_PROPERTY = "ids";
const LABEL_PROPERTY = "value";

const { LOOK_UP_IDS } = Vue.PartFinder;

const resetDependentFields = (field) => {
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });
    const fieldNames = Object.keys(config.fields);
    const dependentFieldsStart = fieldNames.indexOf(field);
    const dependentFieldNames = fieldNames.slice(dependentFieldsStart);

    dependentFieldNames.forEach((field) => {
        resetField(fieldsConfig[field]);
    });

    searchButton.isDisabled = true;
};

const resolveNextDependentFields = (currentField) => {
    if (fieldsConfig[currentField]?.model?.value) {
        const fieldValue = fieldsConfig[currentField].model;
        const config = getOptionsConfigBySelectedApplicationTypes({
            optionsConfig,
            type: initialField.model,
        });

        config.fields[currentField].onInput(fieldValue, true);
    }
};

const resolveDependentFields = (field) => (value, includeDependentFieldsItems) => {
    if (!includeDependentFieldsItems) {
        resetDependentFields(field);
    }
    fieldsConfig[field].disabled = false;

    FIELDS_ACTIONS[field](value, includeDependentFieldsItems);
};

const enableSearchButton = () => {
    searchButton.isDisabled = false;
};

const setModelAndEmitAction = (field, resolveNextDeps) => {
    fieldsConfig[field].model = fieldsConfig[field].items[0];
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });

    config.fields[field].onInput(fieldsConfig[field].items[0], resolveNextDeps);
};

/* Handlers */

const onApplicationTypeInput = (type, includeDependentFieldsItems) => {
    const config = getOptionsConfigBySelectedApplicationTypes({ optionsConfig, type });

    if (config) {
        config.onInput(type, includeDependentFieldsItems);
    }
};

/* Get data */

const getData = (fieldKey, fieldApiService, queryData, resolveNextDeps) => {
    fieldApiService(queryData)
        .then((res) => {
            const resField = getResultField(res, fieldKey);

            if (!resField?.length) {
                fieldsConfig[fieldKey].items = [EMPTY_ITEM];
                setModelAndEmitAction(fieldKey, resolveNextDeps);
            } else {
                const valueProperty =
                    fieldKey === FIELDS.USAGE ? PLURAL_VALUE_PROPERTY : VALUE_PROPERTY;
                fieldsConfig[fieldKey].items = resField.map(
                    getOptionsFromResponse(valueProperty, LABEL_PROPERTY),
                );
                if (resField.length === 1) {
                    setModelAndEmitAction(fieldKey, resolveNextDeps);
                } else if (resolveNextDeps) {
                    resolveNextDependentFields(fieldKey);
                }
            }
        })
        .catch((e) => {
            console.error(e);
            fieldsConfig[fieldKey].items = [];
        })
        .finally(() => {
            fieldsConfig[fieldKey].loading = false;

            setTimeout(() => {
                if(isGPI()) {
                    const eleContainer = document.querySelector('.' + fieldKey);
                    eleContainer.querySelector('input').focus();
                }
            }, 0);
        });
};

const getManufacturers = (value, resolveNextDeps) => {
    const vehicleGroupIds = value?.value;
    const queryData = { vehicleGroupIds };

    fieldsConfig[FIELDS.MANUFACTURER].loading = true;

    getData(
        FIELDS.MANUFACTURER,
        Vue.CatalogApi.CatalogApiService.getEngineManufacturer,
        queryData,
        resolveNextDeps,
    );
};

const getHeavyDutyManufacturers = (value, resolveNextDeps) => {
    const vehicleGroupIds = value?.value;
    fieldsConfig[FIELDS.HEAVY_DUTY_MFRS].loading = true;

    getData(
        FIELDS.HEAVY_DUTY_MFRS,
        Vue.CatalogApi.CatalogApiService.getHeavyDutyManufacturer,
        vehicleGroupIds,
        resolveNextDeps,
    );
};

const getHeavyDutyBase = (value, resolveNextDeps) => {
    const vehicleGroupIds = initialField.model.value;
    const mfrId = value?.value;
    const queryData = { vehicleGroupIds, mfrId };

    fieldsConfig[FIELDS.HEAVY_DUTY_BASE].loading = true;

    getData(
        FIELDS.HEAVY_DUTY_BASE,
        Vue.CatalogApi.CatalogApiService.getHeavyDutyEngineBase,
        queryData,
        resolveNextDeps,
    );
};

const getPerformanceManufacturers = (value, resolveNextDeps) => {
    const vehicleTypeId = value?.value;
    const vehicleGroupIds = initialField.model.value;
    const queryData = { vehicleTypeId, vehicleGroupIds };

    fieldsConfig[FIELDS.PERFORMANCE_MFRS].loading = true;

    getData(
        FIELDS.PERFORMANCE_MFRS,
        Vue.CatalogApi.CatalogApiService.getEnginePerformanceManufacturer,
        queryData,
        resolveNextDeps,
    );
};

const getPerformanceBase = (value, resolveNextDeps) => {
    const vehicleGroupIds = initialField.model.value;
    const vehicleTypeId = fieldsConfig[FIELDS.VEHICLE].model.value;
    const engineMfr = value?.value;

    const queryData = { vehicleTypeId, vehicleGroupIds, engineMfr };

    fieldsConfig[FIELDS.PERFORMANCE_BASE].loading = true;

    getData(
        FIELDS.PERFORMANCE_BASE,
        Vue.CatalogApi.CatalogApiService.getEnginePerformanceBase,
        queryData,
        resolveNextDeps,
    );
};

const getBase = (value, resolveNextDeps) => {
    const vehicleGroupIds = initialField.model.value;
    const mfrId = value?.value;
    const queryData = { vehicleGroupIds, mfrId };

    fieldsConfig[FIELDS.BASE].loading = true;

    getData(
        FIELDS.BASE,
        Vue.CatalogApi.CatalogApiService.getEngineBase,
        queryData,
        resolveNextDeps,
    );
};

const getUsage = (value, resolveNextDeps) => {
    const vehicleGroupIds = initialField.model.value;
    const mfrId = fieldsConfig[FIELDS.MANUFACTURER].model.value;
    const baseId = value?.value;
    const queryData = { vehicleGroupIds, mfrId, baseId };

    fieldsConfig[FIELDS.USAGE].loading = true;

    getData(
        FIELDS.USAGE,
        Vue.CatalogApi.CatalogApiService.getEngineUsage,
        queryData,
        resolveNextDeps,
    );
};

const getVehicle = (value, resolveNextDeps) => {
    const vehicleGrId = value?.value;
    const queryData = { vehicleGrId };

    fieldsConfig[FIELDS.VEHICLE].loading = true;

    getData(
        FIELDS.VEHICLE,
        Vue.CatalogApi.CatalogApiService.getPerformanceVehicleTypes,
        queryData,
        resolveNextDeps,
    );
};

const FIELDS_ACTIONS = {
    [FIELDS.MANUFACTURER]: getManufacturers,
    [FIELDS.BASE]: getBase,
    [FIELDS.USAGE]: getUsage,
    [FIELDS.VEHICLE]: getVehicle,
    [FIELDS.PERFORMANCE_MFRS]: getPerformanceManufacturers,
    [FIELDS.PERFORMANCE_BASE]: getPerformanceBase,
    [FIELDS.HEAVY_DUTY_MFRS]: getHeavyDutyManufacturers,
    [FIELDS.HEAVY_DUTY_BASE]: getHeavyDutyBase,
};

const getApplicationTypes = (applicationTypesString) => {
    initialField.loading = true;

    const applicationTypes = JSON.parse(applicationTypesString);
    const applicationTypeLabelKey = "engineApplicationType";
    const applicationTypeValueKey = "engineGroupId";

    Promise.resolve(
        applicationTypes.map(
            getApplicationTypesOptionsFromResponse(
                applicationTypeValueKey,
                applicationTypeLabelKey,
            ),
        ),
    )
        .then((res) => (initialField.items = res))
        .finally(() => {
            initialField.loading = false;
        });
};

/* Configs */

const fieldsConfig = INITIAL_FIELDS_CONFIG;
const fieldsOptionsFromAEM = Object.keys(INITIAL_FIELDS_CONFIG).reduce((acc, fieldKey) => {
    acc[fieldKey] = null;
    return acc;
}, {});

const optionsConfig = {
    [LOOK_UP_IDS.ENGINE_LIGHT_MEDIUM_DUTY]: {
        onInput: resolveDependentFields(FIELDS.MANUFACTURER),
        fields: {
            [FIELDS.MANUFACTURER]: {
                onInput: resolveDependentFields(FIELDS.BASE),
            },
            [FIELDS.BASE]: {
                onInput: resolveDependentFields(FIELDS.USAGE),
            },
            [FIELDS.USAGE]: {
                onInput: enableSearchButton,
            },
        },
    },
    [LOOK_UP_IDS.ENGINE_PERFORMANCE]: {
        onInput: resolveDependentFields(FIELDS.VEHICLE),
        fields: {
            [FIELDS.VEHICLE]: {
                onInput: resolveDependentFields(FIELDS.PERFORMANCE_MFRS),
            },
            [FIELDS.PERFORMANCE_MFRS]: {
                onInput: resolveDependentFields(FIELDS.PERFORMANCE_BASE),
            },
            [FIELDS.PERFORMANCE_BASE]: {
                onInput: enableSearchButton,
            },
        },
    },
    [LOOK_UP_IDS.HEAVY_DUTY]: {
        onInput: resolveDependentFields(FIELDS.HEAVY_DUTY_MFRS),
        fields: {
            [FIELDS.HEAVY_DUTY_MFRS]: {
                onInput: resolveDependentFields(FIELDS.HEAVY_DUTY_BASE),
            },
            [FIELDS.HEAVY_DUTY_BASE]: {
                onInput: enableSearchButton,
            },
        },
    },
};

const searchButton = {
    isDisabled: true,
};

const initialField = {
    ...INITIAL_FIELDS_CONFIG[FIELDS.APPLICATION_TYPE],
    name: FIELDS.APPLICATION_TYPE,
    getItems: getApplicationTypes,
    onInput: onApplicationTypeInput,
};

export default {
    fieldsConfig,
    fieldsOptionsFromAEM,
    optionsConfig,
    searchButton,
    initialField,
};
