var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "refine-search-fields" },
    [
      _c("fmmp-autocomplete", {
        class:
          "driv-part-common " +
          _vm.initialField.name +
          " " +
          _vm.searchFieldWidthClass,
        attrs: {
          config: _vm.autocompleteConfig,
          id: _vm.initialField.name,
          name: _vm.initialField.name,
          items: _vm.initialField.items,
          placeholder: _vm.getTranslation(_vm.initialField.placeholder),
          loading: _vm.initialField.loading,
          showLabel: _vm.showLabels
        },
        on: { input: _vm.initialField.onInput },
        model: {
          value: _vm.initialField.model,
          callback: function($$v) {
            _vm.$set(_vm.initialField, "model", $$v)
          },
          expression: "initialField.model"
        }
      }),
      _vm._v(" "),
      _vm.initialField.model
        ? [
            _vm._l(_vm.options, function(field) {
              return _c("fmmp-autocomplete", {
                key: field,
                class:
                  "driv-part-common " + field + " " + _vm.searchFieldWidthClass,
                attrs: {
                  config: _vm.autocompleteConfig,
                  id: field,
                  name: field,
                  items: _vm.fieldsConfig[field].items,
                  disabled: _vm.fieldsConfig[field].disabled,
                  placeholder: _vm.getTranslation(
                    _vm.fieldsConfig[field].placeholder
                  ),
                  loading: _vm.fieldsConfig[field].loading,
                  showLabel: _vm.showLabels
                },
                on: {
                  input: function(value) {
                    return _vm.handleFieldInput(field, value)
                  }
                },
                model: {
                  value: _vm.fieldsConfig[field].model,
                  callback: function($$v) {
                    _vm.$set(_vm.fieldsConfig[field], "model", $$v)
                  },
                  expression: "fieldsConfig[field].model"
                }
              })
            }),
            _vm._v(" "),
            _vm.searchButton
              ? _c("driv-refine-search-btn", {
                  attrs: {
                    disabled: _vm.searchButton.isDisabled,
                    onClick: _vm.handleSearch
                  }
                })
              : _vm._e()
          ]
        : _c("div", { staticClass: "search-txt desktop" }, [
            _vm._v(_vm._s(_vm.searchValuesString))
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }