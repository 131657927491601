import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { getQsParams } from "../helpers";
import { PART_NUMBER_PROPERTY, SPECIFICATION_PROPERTIES, APPLY_RANGE_FILTER } from "../constants";

const TABLET_BREAKPOINT = 768;
const COLUMN_WIDTH_FOR_TABLET_AND_LESS = 120;
const COLUMN_WIDTH_FOR_DESKTOP = 200;

export const setColAndDataToTable = ({
    gridApi,
    specifications,
    prepareColumns,
    prepareTableData,
}) => {
    const masterList = specifications[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY];
    const dataList = specifications[SPECIFICATION_PROPERTIES.SPECIFICATION_LIST_PROPERTY] || {};
    const data = dataList[SPECIFICATION_PROPERTIES.SPECIFICATIONS_PROPERTY];
    if (masterList?.length && data?.length) {
        setTimeout(() => gridApi?.setColumnDefs(prepareColumns(masterList)), 0);
        setTimeout(() => gridApi?.setRowData(prepareTableData(data)), 0);
    }
};

const setColumnsWidth = (columnWidth, { gridApi, gridColumnApi }) => {
    const dataWidth = (gridApi?.getColumnDefs()?.length || 1) * columnWidth;
    gridColumnApi?.sizeColumnsToFit(dataWidth < window.innerWidth ? window.innerWidth : dataWidth);
};

export const setInitColsWidth = (apis) => {
    window.innerWidth <= TABLET_BREAKPOINT
        ? setColumnsWidth(COLUMN_WIDTH_FOR_TABLET_AND_LESS, apis)
        : setColumnsWidth(COLUMN_WIDTH_FOR_DESKTOP, apis);
};

const PART_NUMBER_COLUMN_CONFIG = {
    field: PART_NUMBER_PROPERTY,
    pinned: "left",
    cellRenderer: "partNumberCellRenderer",
    headerName: getTranslation("Part Number"),
};

export const prepareColumns = (masterList, getCol) => {
    return (masterList || []).reduce(
        (acc, item) => [...acc, getCol(item)],
        [PART_NUMBER_COLUMN_CONFIG],
    );
};

export const prepareTableData = (data, getTableItem, detailsPath) => {
    return data.map((item) => {
        return (item[SPECIFICATION_PROPERTIES.ATTRIBUTES_PROPERTY] || []).reduce(
            (acc, attr) => {
                return {
                    ...acc,
                    ...getTableItem(attr),
                };
            },
            {
                [PART_NUMBER_PROPERTY]: item.part_number,
                brand: item.brand,
                detailsPath,
            },
        );
    });
};

export const coverFuncWithAnalytics = ({ func, eventAction, rangeFilters }) => {
    const functionName = func;
    const { specificationType, partTypeId, brands } = getQsParams();
    const eventParams = [specificationType.label, brands.label, partTypeId.label];

    if (eventAction === APPLY_RANGE_FILTER) {
        const filters = rangeFilters
            .map((item) => {
                if (item.name) {
                    return `${item.name?.label}~${item.value_from}-${item.value_to}`;
                }
            })
            .join("|");
        eventParams.push(filters);
    }

    Vue.Global.Analytics.trackEvent(
        "specification-part-results",
        eventAction,
        eventParams.join("|"),
        null,
        {
            callback: function () {
                functionName();
            },
        },
    );
};
