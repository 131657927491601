<template>
    <a @click="handleGoToDetails">{{ params.value }}</a>
</template>

<script>
import { createUrlToGoToSearchResults } from "../../../../common/partFinderCorporate.helpers";

export default Vue.extend({
    methods: {
        handleGoToDetails() {
            const { data } = this.params;

            if (!data.detailsPath) return;

            return Vue.Global.Analytics.trackEvent(
                "Part Search Results Link",
                "Part",
                data.partNumber,
                null,
                {
                    callback: function () {
                        window.location.assign(
                            createUrlToGoToSearchResults({
                                queryObj: { part_number: data.partNumber, brand_code: data.brand },
                                path: data.detailsPath,
                            }),
                        );
                    },
                },
            );
        },
    },
});
</script>
