<template>
    <div>
        <div
            :class="['driv-refine-search-container', { sticky: isSticky.status }, {'show-labels': showLabels}]"
            ref="mainContainer"
        >
            <div class="mobile-container" ref="mobileHeaderContainer">
                <div class="search-txt">{{ searchValuesString }}</div>
                <button class="refine-search" @click="handleRefineSearchClick">
                    {{ isEditSearchMode ? "‒" : "+" }}
                </button>
            </div>
            <form
                :class="[
                    'driv-part-common search-container',
                    { 'mobile-hidden': !isEditSearchMode },
                    currentSearchTypeOption && currentSearchTypeOption.value,
                ]"
            >
                <fmmp-autocomplete
                    v-show="isMoreThanOneSearchType"
                    :class="`driv-part-common search-type ${
                        currentSearchTypeOption && currentSearchTypeOption.value
                    }`"
                    :items="searchTypeOptions"
                    :value="currentSearchTypeOption"
                    :config="autocompleteConfig"
                    @input="handleSearchTypeInput($event)"
                    placeholder="Lookup"
                    :showLabel="showLabels"
                >
                </fmmp-autocomplete>
                <driv-refine-ymm-search
                    v-if="checkForSearchType(allSearchTypes.VEHICLE)"
                    :searchParams="searchParams"
                    :vehicleTypes="vehicleTypes"
                    :defaultVehicleTypeId="defaultVehicleTypeId"
                    :searchValuesString="refineYmmSearchString"
                    :subBrand="subBrand"
                    :showLabels="showLabels"
                ></driv-refine-ymm-search>
                <driv-refine-part-number-search
                    v-else-if="checkForSearchType(allSearchTypes.PART_NUMBER_SEARCH)"
                    :partNumber="searchParams.part"
                    :showLabels="showLabels"
                ></driv-refine-part-number-search>
                <driv-refine-license-plate-search
                    v-else-if="checkForSearchType(allSearchTypes.LICENSE_PLATE)"
                    :licensePlateNumber="searchParams.licensePlateNumber"
                    :states="states"
                    :stateId="searchParams.stateId"
                    :subBrand="subBrand"
                    :showLabels="showLabels"
                ></driv-refine-license-plate-search>
                <driv-refine-vin-search
                    v-else-if="checkForSearchType(allSearchTypes.VIN)"
                    :vin="searchParams.vin"
                    :subBrand="subBrand"
                    :showLabels="showLabels"
                ></driv-refine-vin-search>
                <driv-refine-engine-search
                    v-else-if="isEngineSearchType"
                    :searchParams="searchParams"
                    :vehicleTypes="vehicleTypes"
                    :engineTypes="engineTabTypes"
                    :searchValuesString="refineEngineSearchString"
                    :subBrand="subBrand"
                    :showLabels="showLabels"
                ></driv-refine-engine-search>
                <driv-refine-specification-search
                    v-else-if="checkForSearchType(allSearchTypes.SPECIFICATION)"
                    :searchParams="searchParams"
                    :specificationTypes="specificationTypes"
                    :specificationBrandType="specificationBrandType"
                    :subBrand="subBrand"
                    :torqueContentSilos="torqueSilosToGroupIdMap"
                    :showLabels="showLabels"
                ></driv-refine-specification-search>
            </form>
        </div>
        <div ref="placeholder" class="driv-refine-search-container-placeholder" />
    </div>
</template>

<script>
import {
    getTranslation,
    mapLicensePlateStatesArray,
} from "../../../common/partFinderCorporate.helpers";
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    PART_FINDER_CORPORATE_TABS,
} from "../../../common/partFinderCorporate.constants";
import { getBrandsFromTags } from "../../../common/ymm-search/helpers";
import { EMPTY_ITEM } from "../../../common/specification-search/constants";

const { LOOK_UP_IDS } = Vue.PartFinder;

const PART_FINDER_CORPORATE_SEARCH_OPTIONS = PART_FINDER_CORPORATE_TABS.map((option) => ({
    ...option,
    label: getTranslation(
        option.label +
            (option.value !== PART_FINDER_CORPORATE_SEARCH_TYPES.PART_NUMBER_SEARCH
                ? ` search`
                : ""),
    ),
}));

const lightDutySearchProperties = ["engineMfr", "engineBase", "engineUsage", "engineVersion"];
const performanceSearchProperties = ["", "vehicle", "performanceMfr", "performanceBase"];
const heavyDutySearchProperties = ["", "heavyMfr", "heavyBase"];
const specificationSearchProperties = ["brands", "partTypeId", "partDescription"];
const torqueSearchProperties = ["contentSilos"];
const vehicleSearchProperties = [
    "vehicleType",
    "year",
    "make",
    "model",
    "vehicle",
    "mfr",
    "equipmentModel",
    "equipmentYear",
];
const ENGINE_SEARCH_TYPES = [
    PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE,
    PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE,
];

export default Vue.component("driv-refine-search", {
    data() {
        return {
            searchTypeOptions: [],
            currentSearchType: this.initialSearchType,
            allSearchTypes: PART_FINDER_CORPORATE_SEARCH_TYPES,
            isSticky: {
                status: false,
                isMobile: false,
            },
            isEditSearchMode: false,
            searchParams: {
                searchType: "",
                vehicleType: {},
                year: {},
                make: {},
                model: {},
                vehicle: {},
                mfr: {},
                equipmentModel: {},
                equipmentYear: {},
                part: "",
                stateId: "",
                licensePlateNumber: "",
                vin: "",
                engineMfr: {},
                engineBase: {},
                engineUsage: {},
                engineVersion: {},
                performanceBase: {},
                specificationType: {},
                brands: {},
                partDescription: {},
                partTypeId: {},
                contentSilos: {},
            },
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            states: [],
            subBrand: "",
        };
    },
    props: {
        showLabels: {
            type: Boolean,
            default: false,
        },
        searchTypesToDisplay: {
            type: String,
            default: "",
        },
        initialSearchType: {
            type: String,
            default: "",
        },
        vehicleTypes: {
            type: String,
            default: "",
        },
        engineTypes: {
            type: String,
            default: "",
        },
        performanceTypes: {
            type: String,
            default: "",
        },
        defaultVehicleTypeId: {
            type: String,
            default: "",
        },
        specificationTypes: {
            type: String,
            default: "",
        },
        specificationBrandType: {
            type: String,
            default: "",
        },
        tags: {
            type: String,
            default: "",
        },
        torqueSilosToGroupIdMap: {
            type: String,
            default: "",
        },
    },
    computed: {
        engineTabTypes() {
            if (this.isPerformanceSearch) return this.performanceTypes;
            return this.engineTypes;
        },
        isPerformanceSearch() {
            return this.currentSearchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
        },
        isEngineSearchType() {
            return ENGINE_SEARCH_TYPES.includes(this.currentSearchTypeOption?.value);
        },
        currentSearchTypeOption() {
            return this.searchTypeOptions.find((option) => option.value === this.currentSearchType);
        },
        isMoreThanOneSearchType() {
            return this.searchTypeOptions.length > 1;
        },
        searchValuesString() {
            let searchValues = [];

            switch (true) {
                case !!this.searchParams.part:
                    searchValues = [this.searchParams.part.toUpperCase()];
                    break;
                case !!this.searchParams.vin:
                    searchValues = [this.searchParams.vin.toUpperCase()];
                    break;
                case !!(this.searchParams.stateId && this.searchParams.licensePlateNumber): {
                    const stateLabel = this.states.find(
                        ({ id }) => id == this.searchParams.stateId,
                    )?.label;

                    if (stateLabel) {
                        searchValues = [
                            stateLabel,
                            this.searchParams.licensePlateNumber.toUpperCase(),
                        ];
                    }
                    break;
                }
                case this.searchParams.vehicleGroupIds?.value ===
                    LOOK_UP_IDS.ENGINE_LIGHT_MEDIUM_DUTY:
                    searchValues = this.createSearchValuesString(lightDutySearchProperties);
                    break;
                case this.searchParams.vehicleGroupIds?.value === LOOK_UP_IDS.ENGINE_PERFORMANCE:
                    searchValues = this.createSearchValuesString(performanceSearchProperties);
                    break;
                case this.searchParams.vehicleGroupIds?.value === LOOK_UP_IDS.HEAVY_DUTY:
                    searchValues = this.createSearchValuesString(heavyDutySearchProperties);
                    break;
                case this.searchParams.searchType ===
                    PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION:
                    searchValues = this.createSearchValuesString(specificationSearchProperties);
                    break;
                case this.searchParams.searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.TORQUE:
                    searchValues = this.createSearchValuesString(torqueSearchProperties);
                    break;
                default: {
                    searchValues = this.createSearchValuesString(vehicleSearchProperties);
                    break;
                }
            }

            if (!searchValues.length) {
                return this.getTranslation(
                    "Something went wrong! Please check your search criteria.",
                );
            }

            return searchValues.join(", ");
        },
        refineYmmSearchString() {
            return !this.searchParams.searchType ||
                this.searchParams.searchType === this.allSearchTypes.VEHICLE
                ? this.searchValuesString
                : "";
        },
        refineEngineSearchString() {
            return !this.searchParams.searchType ||
                this.searchParams.searchType === this.allSearchTypes.ENGINE
                ? this.searchValuesString
                : "";
        },
        updatedSearchType() {
            switch (this.searchParams.searchType) {
                case this.allSearchTypes.TORQUE:
                    return this.allSearchTypes.SPECIFICATION;
                case this.allSearchTypes.ENGINE_HEAVY_DUTY:
                    return this.allSearchTypes.ENGINE;
                default:
                    return this.searchParams.searchType;
            }
        },
    },
    methods: {
        getTranslation,
        handleScroll() {
            const scrollPosition = window.pageYOffset;
            const pageHeaderHeight = document.querySelector(".page-header").scrollHeight;

            if (scrollPosition >= pageHeaderHeight) {
                if (!this.isSticky.status) {
                    this.setStickyBar(true);
                }
            } else if (this.isSticky.status) {
                this.setStickyBar(false);
            }
        },
        handleResize() {
            if (this.isSticky.status) {
                const isMobileBarView = this.getIsMobileBarView();

                if (this.isSticky.isMobile !== isMobileBarView) this.setStickyBar(true);
            }
        },
        setStickyBar(newStickyMode) {
            const placeholderEl = this.$refs.placeholder;

            if (!newStickyMode) {
                this.isSticky.status = false;
                placeholderEl.style.height = 0;
                return;
            }

            placeholderEl.style.height = `${this.$refs.mainContainer.clientHeight}px`;
            this.isSticky = {
                status: true,
                isMobile: this.getIsMobileBarView(),
            };
        },
        getIsMobileBarView() {
            return this.$refs.mobileHeaderContainer.offsetParent !== null;
        },
        handleRefineSearchClick() {
            this.isEditSearchMode = !this.isEditSearchMode;
        },
        handleSearchTypeInput(option) {
            this.currentSearchType = option?.value;
        },
        createSearchValuesString(array) {
            return array.reduce((acc, val) => {
                if (
                    this.searchParams[val]?.label &&
                    this.searchParams[val]?.label !== EMPTY_ITEM.label
                ) {
                    acc = [...acc, this.searchParams[val].label.toUpperCase()];
                }
                return acc;
            }, []);
        },
        checkForSearchType(searchType) {
            return this.currentSearchTypeOption?.value === searchType;
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);

        const searchTypesArray = this.searchTypesToDisplay.split(",") || [];

        this.subBrand = getBrandsFromTags(this.tags);

        if (searchTypesArray.length) {
            this.searchTypeOptions = searchTypesArray
                .map((type) =>
                    PART_FINDER_CORPORATE_SEARCH_OPTIONS.find((option) => type === option.value),
                )
                .filter(Boolean);
        }

        const params = Qs.parse(window.location.search.slice(1));
        const {
            searchType,
            type,
            make,
            model,
            year,
            vehicle,
            mfr,
            equipmentModel,
            equipmentYear,
            part,
            state,
            licensePlateNumber,
            vin,
            vehicleGroupIds,
            engineMfr,
            performanceMfr,
            performanceBase,
            engineBase,
            engineUsage,
            engineVersion,
            heavyMfr,
            heavyBase,
            partTypeId,
            brands,
            partDescription,
            specificationType,
            contentSilos,
        } = params;

        // double decoding here is to avoid situation when in encoded url we have light%2520vehicle,
        // this decoded by Qs to light%20vehicle, so after additional decoding we will get correct UI string
        this.searchParams = {
            searchType: decodeURIComponent(searchType || ""),
            vehicleType: {
                label: decodeURIComponent(type?.label || ""),
                value: decodeURIComponent(type?.value || ""),
            },
            year: {
                label: decodeURIComponent(year?.label || ""),
                value: decodeURIComponent(year?.value || ""),
            },
            make: {
                label: decodeURIComponent(make?.label || ""),
                value: decodeURIComponent(make?.value || ""),
            },
            model: {
                label: decodeURIComponent(model?.label || ""),
                value: decodeURIComponent(model?.value || ""),
            },
            vehicle: {
                label: decodeURIComponent(vehicle?.label || ""),
                value: decodeURIComponent(vehicle?.value || ""),
            },
            mfr: {
                label: decodeURIComponent(mfr?.label || ""),
                value: decodeURIComponent(mfr?.value || ""),
            },
            equipmentModel: {
                label: decodeURIComponent(equipmentModel?.label || ""),
                value: decodeURIComponent(equipmentModel?.value || ""),
            },
            equipmentYear: {
                label: decodeURIComponent(equipmentYear?.label || ""),
                value: decodeURIComponent(equipmentYear?.value || ""),
            },
            part: decodeURIComponent(part || ""),
            stateId: decodeURIComponent(state || ""),
            licensePlateNumber: decodeURIComponent(licensePlateNumber || ""),
            vin: decodeURIComponent(vin || ""),
            vehicleGroupIds: {
                label: decodeURIComponent(vehicleGroupIds?.label || ""),
                value: decodeURIComponent(vehicleGroupIds?.value || ""),
            },
            engineMfr: {
                label: decodeURIComponent(engineMfr?.label || ""),
                value: decodeURIComponent(engineMfr?.value || ""),
            },
            performanceMfr: {
                label: decodeURIComponent(performanceMfr?.label || ""),
                value: decodeURIComponent(performanceMfr?.value || ""),
            },
            performanceBase: {
                label: decodeURIComponent(performanceBase?.label || ""),
                value: decodeURIComponent(performanceBase?.value || ""),
            },
            engineBase: {
                label: decodeURIComponent(engineBase?.label || ""),
                value: decodeURIComponent(engineBase?.value || ""),
            },
            engineUsage: {
                label: decodeURIComponent(engineUsage?.label || ""),
                value: decodeURIComponent(engineUsage?.value || ""),
            },
            engineVersion: {
                label: decodeURIComponent(engineVersion?.label || ""),
                value: decodeURIComponent(engineVersion?.value || ""),
            },
            heavyMfr: {
                label: decodeURIComponent(heavyMfr?.label || ""),
                value: decodeURIComponent(heavyMfr?.value || ""),
            },
            heavyBase: {
                label: decodeURIComponent(heavyBase?.label || ""),
                value: decodeURIComponent(heavyBase?.value || ""),
            },
            brands: {
                label: decodeURIComponent(brands?.label || ""),
                value: decodeURIComponent(brands?.value || ""),
            },
            partTypeId: {
                label: decodeURIComponent(partTypeId?.label || ""),
                value: decodeURIComponent(partTypeId?.value || ""),
            },
            partDescription: {
                label: decodeURIComponent(partDescription?.label || ""),
                value: decodeURIComponent(partDescription?.value || ""),
            },
            specificationType: {
                label: decodeURIComponent(specificationType?.label || ""),
                value: decodeURIComponent(specificationType?.value || ""),
            },
            contentSilos: {
                label: decodeURIComponent(contentSilos?.label || ""),
                value: decodeURIComponent(contentSilos?.value || ""),
            },
        };

        if (searchType) {
            this.currentSearchType = this.updatedSearchType;
        } else if (!this.initialSearchType && this.searchTypeOptions[0]) {
            this.currentSearchType = this.searchTypeOptions[0].value;
        }

        Vue.CatalogApi.CatalogApiService.getLicensePlateStates().then((response) => {
            this.states = mapLicensePlateStatesArray(response);
        });
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    },
});
</script>
