var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "mainContainer",
        class: [
          "driv-refine-search-container",
          { sticky: _vm.isSticky.status },
          { "show-labels": _vm.showLabels }
        ]
      },
      [
        _c(
          "div",
          { ref: "mobileHeaderContainer", staticClass: "mobile-container" },
          [
            _c("div", { staticClass: "search-txt" }, [
              _vm._v(_vm._s(_vm.searchValuesString))
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "refine-search",
                on: { click: _vm.handleRefineSearchClick }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.isEditSearchMode ? "‒" : "+") +
                    "\n            "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            class: [
              "driv-part-common search-container",
              { "mobile-hidden": !_vm.isEditSearchMode },
              _vm.currentSearchTypeOption && _vm.currentSearchTypeOption.value
            ]
          },
          [
            _c("fmmp-autocomplete", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMoreThanOneSearchType,
                  expression: "isMoreThanOneSearchType"
                }
              ],
              class:
                "driv-part-common search-type " +
                (_vm.currentSearchTypeOption &&
                  _vm.currentSearchTypeOption.value),
              attrs: {
                items: _vm.searchTypeOptions,
                value: _vm.currentSearchTypeOption,
                config: _vm.autocompleteConfig,
                placeholder: "Lookup",
                showLabel: _vm.showLabels
              },
              on: {
                input: function($event) {
                  return _vm.handleSearchTypeInput($event)
                }
              }
            }),
            _vm._v(" "),
            _vm.checkForSearchType(_vm.allSearchTypes.VEHICLE)
              ? _c("driv-refine-ymm-search", {
                  attrs: {
                    searchParams: _vm.searchParams,
                    vehicleTypes: _vm.vehicleTypes,
                    defaultVehicleTypeId: _vm.defaultVehicleTypeId,
                    searchValuesString: _vm.refineYmmSearchString,
                    subBrand: _vm.subBrand,
                    showLabels: _vm.showLabels
                  }
                })
              : _vm.checkForSearchType(_vm.allSearchTypes.PART_NUMBER_SEARCH)
              ? _c("driv-refine-part-number-search", {
                  attrs: {
                    partNumber: _vm.searchParams.part,
                    showLabels: _vm.showLabels
                  }
                })
              : _vm.checkForSearchType(_vm.allSearchTypes.LICENSE_PLATE)
              ? _c("driv-refine-license-plate-search", {
                  attrs: {
                    licensePlateNumber: _vm.searchParams.licensePlateNumber,
                    states: _vm.states,
                    stateId: _vm.searchParams.stateId,
                    subBrand: _vm.subBrand,
                    showLabels: _vm.showLabels
                  }
                })
              : _vm.checkForSearchType(_vm.allSearchTypes.VIN)
              ? _c("driv-refine-vin-search", {
                  attrs: {
                    vin: _vm.searchParams.vin,
                    subBrand: _vm.subBrand,
                    showLabels: _vm.showLabels
                  }
                })
              : _vm.isEngineSearchType
              ? _c("driv-refine-engine-search", {
                  attrs: {
                    searchParams: _vm.searchParams,
                    vehicleTypes: _vm.vehicleTypes,
                    engineTypes: _vm.engineTabTypes,
                    searchValuesString: _vm.refineEngineSearchString,
                    subBrand: _vm.subBrand,
                    showLabels: _vm.showLabels
                  }
                })
              : _vm.checkForSearchType(_vm.allSearchTypes.SPECIFICATION)
              ? _c("driv-refine-specification-search", {
                  attrs: {
                    searchParams: _vm.searchParams,
                    specificationTypes: _vm.specificationTypes,
                    specificationBrandType: _vm.specificationBrandType,
                    subBrand: _vm.subBrand,
                    torqueContentSilos: _vm.torqueSilosToGroupIdMap,
                    showLabels: _vm.showLabels
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("div", {
      ref: "placeholder",
      staticClass: "driv-refine-search-container-placeholder"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }