<template>
    <div>
        <driv-empty-part-results-page v-if="error"></driv-empty-part-results-page>
        <div class="driv-part-results-content" v-else>
            <div class="part-results-head">
                <div class="product-logo" :key="componentKey">
                    <fmmp-brandlogo :option="brandLogo" :isResult="true"
                        @onErrorImageLoad="onErrorImageLoad"></fmmp-brandlogo>
                </div>
                <div class="popup-buttons">
                    <driv-info-modal v-if="isChampionBrand" :infoModalText="infoModal" />
                </div>
            </div>

            <div v-bind:class="[
                'clear-filters-block',
                isGurusProductInsights && numericFieldsNames.length ? 'absolute-position' : '',
            ]">
                <button class="button-main reset-filters" @click="resetFilters" :disabled="isClearFiltersDisabled">
                    <fmmp-i18n text="Clear Filters" />
                </button>
            </div>

            <div class="results-summary">
                <span class="results-quantity">
                    {{ pagination.total }}
                    <fmmp-i18n text="Part Results" />
                </span>
                <driv-corp-list-pagination v-if="isPaginationShown" :savedPage="pagination.page" :totalPages="pagesQty"
                    :onPageChange="handlePageChange" />
            </div>

            <div class="specification-table">
                <fmmp-table :columnConfig="columnConfig" :tableData="tableData" :gridOptions="tableProps"></fmmp-table>
            </div>

            <div class="results-summary footer" v-if="isPaginationShown">
                <div class="results-quantity">
                    {{ pagination.total }}
                    <fmmp-i18n text="Part Results" />
                </div>
                <driv-corp-list-pagination :savedPage="pagination.page" :totalPages="pagesQty"
                    :onPageChange="(v) => handlePageChange(v, true)" />
            </div>
        </div>
    </div>
</template>

<script>
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";
import { PART_DESCRIPTION_ATTRIBUTE_NAME } from "../../../../common/specification-search/constants";
import {
    CHAMPION_BRAND,
    SPECIFICATION_PROPERTIES,
    SCROLL_BAR_WIDTH,
    COLUMN_NAMES,
    SPECIFICATION_MOUNT_CODE_HOVER_TEXT,
    MONROE_BRAND,
    RANCHO_BRAND,
    MOUNT_CODE_TABLE_PATH_LABEL
} from "../constants";
import { getHashParams, getQsParams } from "../helpers";
import Filter from "./SpecificationResultsFilter.vue";
import ClickablePartNumber from "./ClickablePartNumber.vue";
import RangeFilter from "./RangeFilterBlock.vue";
import {
    prepareColumns,
    prepareTableData,
    setColAndDataToTable,
    setInitColsWidth,
} from "./specification.helpers";

const DEFAULT_PAGE_SIZE = 50;

export default Vue.component("driv-corp-specification-results", {
    data() {
        return {
            componentKey: 0,
            columnConfig: [],
            tableData: [],
            tableProps: {
                paginationPageSize: DEFAULT_PAGE_SIZE,
                pagination: true,
                suppressPaginationPanel: true,
                suppressScrollOnNewData: true,
                suppressRowHoverHighlight: true,
                suppressCellSelection: true,
                suppressMenuHide: true,
                suppressMovableColumns: true,
                suppressRowDrag: true,
                onFilterChanged: this.onFilterChanged,
                onGridReady: this.onGridReady,
                onFirstDataRendered: this.firstDataRendered,
                scrollbarWidth: SCROLL_BAR_WIDTH,
                icons: {
                    menu: '<i class="fa fa-filter filter-icon" unselectable="on" role="presentation"></i>',
                },
                defaultColDef: {
                    autoHeight: true,
                    wrapText: true,
                },
                frameworkComponents: {
                    partNumberCellRenderer: ClickablePartNumber,
                },
            },
            gridApi: null,
            gridColumnApi: null,
            numericFieldsNames: [],
            isClearFiltersDisabled: true,
            latestFilter: null,
        };
    },
    components: {
        filterComponent: Filter,
        rangeFilterComponent: RangeFilter,
    },
    props: {
        pagination: {
            type: Object,
            default: () => ({}),
        },
        handlePageChange: {
            type: Function,
            default: () => { },
        },
        error: {
            type: Boolean,
            default: false,
        },
        specifications: {
            type: Object,
            default: () => ({}),
        },
        infoModal: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        isPaginationShown() {
            return this.pagination.total > this.pagination.limit;
        },
        pagesQty() {
            return Math.ceil(this.pagination.total / this.pagination.limit);
        },
        brandLogo() {
            const { brands } = getQsParams();
            return { label: brands.label, isUrlValid: true };
        },
        isChampionBrand() {
            return this.brandLogo.label === CHAMPION_BRAND;
        },
        isMonroeBrand() {
            return this.brandLogo.label === MONROE_BRAND;
        },
        isRanchoBrand() {
            return this.brandLogo.label === RANCHO_BRAND;
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
    },
    methods: {
        getTranslation,
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;

            setColAndDataToTable({
                gridApi: this.gridApi,
                specifications: this.specifications,
                prepareColumns: this.prepareColumns,
                prepareTableData: this.prepareTableData,
            });

            // apply initial filters
            const allFilters = getHashParams() || {};
            const tableFilters = (
                allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || []
            ).filter((filter) => filter.name !== PART_DESCRIPTION_ATTRIBUTE_NAME);

            if (tableFilters?.length) {
                const filters = tableFilters.reduce(
                    (acc, filter) => ({
                        ...acc,
                        [filter.uom ? `${filter.name}_${filter.uom}` : filter.name]: {
                            value: filter.value,
                        },
                    }),
                    {},
                );

                setTimeout(() => this.gridApi?.setFilterModel(filters), 0);
            }
        },
        onFilterChanged(params) {
            const selectedFilter = params.selectedFilter || {};
            const allFilters = getHashParams() || {};
            let attributeFilters = allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || [];

            if (selectedFilter.value) {
                const alreadySelectedIndex = attributeFilters.findIndex(
                    (filter) =>
                        filter.name === selectedFilter.name && filter.uom === selectedFilter.uom,
                );
                const deleteCount = alreadySelectedIndex > -1 ? 1 : 0;

                attributeFilters.splice(alreadySelectedIndex, deleteCount, selectedFilter);
            } else {
                attributeFilters = attributeFilters.filter(
                    (filter) => filter.name !== selectedFilter.name,
                );
            }

            this.latestFilter =
                selectedFilter.name + (selectedFilter.uom ? `_${selectedFilter.uom}` : "");

            const updatedFilters = {
                ...allFilters,
                [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: attributeFilters,
            };

            let filterModel = [];
            if (updatedFilters.attribute_range_filters) {
                const selectedRangeFilter = updatedFilters?.attribute_range_filters.map((filter) => {
                    filter.name.name = filter.name.value;
                    return filter.name;
                });
                filterModel = typeof updatedFilters.attribute_filters !== 'undefined' ? [...updatedFilters.attribute_filters, ...selectedRangeFilter] : [...selectedRangeFilter];

            } else {
                filterModel = [...updatedFilters.attribute_filters];
            }

            const headerElements = document.querySelectorAll('.ag-header-cell');

            // Iterate over the header elements and update the class of filtered header cells
            headerElements.forEach((element) => {
                const fieldEle = element.getAttribute('col-id');
                if (filterModel.length) {
                    filterModel.forEach((filter) => {
                        const field = filter.name + (filter.uom ? `_${filter.uom}` : "");
                        if (field === fieldEle) {
                            // adds the active class to the filter
                            setTimeout(() => element.classList.add('ag-header-cell-filtered'), 0);
                        } else {
                            element.classList.remove('ag-header-cell-filtered');
                        }
                    });
                } else {
                    element.classList.remove('ag-header-cell-filtered');
                }
            });

            window.location.hash = Qs.stringify(updatedFilters);
        },
        onErrorImageLoad() {
            this.brandLogo.isUrlValid = false;
            // force re-render
            this.componentKey += 1;
        },
        getPropNameWithUom(value, uomValue) {
            return uomValue ? `${value}_${uomValue}` : value;
        },
        prepareColumns(masterList) {
            return prepareColumns(masterList, (item) => ({
                field: this.getPropNameWithUom(item.name, item.uom),
                headerName: item.uom
                    ? getTranslation(`${item.name} (${item.uom})`)
                    : getTranslation(item.name),
                ...(item?.unique_value_list?.length
                    ? {
                        filter: true,
                        filterParams: {
                            options: item?.unique_value_list,
                            refreshValuesOnOpen: true,
                            isNumeric: item?.is_numeric,
                            numericColumns: this.numericFieldsNames,
                            identifier: this.getPropNameWithUom(item.name, item.uom),
                        },
                        filterFramework: item?.is_numeric ? "rangeFilterComponent" : "filterComponent",
                    }
                    : {}),
            }));
        },
        prepareTableData(data) {
            return prepareTableData(
                data,
                (attr) => ({
                    [this.getPropNameWithUom(attr.attribute, attr.attribute_uom)]:
                        attr.attribute_value,
                }),
                this.detailsPath,
            );
        },
        getNumericFields(masterList) {
            return (masterList || []).reduce((acc, column) => {
                const uom = column.uom ? ` (${column.uom})` : "";
                return column.is_numeric
                    ? [...acc, { value: column.name, label: column.name + uom, uom: column.uom }]
                    : acc;
            }, []);
        },
        resetFilters() {
            setTimeout(() => this.gridApi?.setFilterModel(null), 0);

            const allFilters = getHashParams() || {};
            const partDescriptionFilter = (
                allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || []
            ).find((filter) => filter.name === PART_DESCRIPTION_ATTRIBUTE_NAME);

            setTimeout(() => {
                window.location.hash = partDescriptionFilter
                    ? Qs.stringify({
                        [SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]: [partDescriptionFilter],
                    })
                    : "";
            }, 0);
        },
        handleUrlChange({ newURL, oldURL }) {
            // hash event fires twice,
            // because url is decoded to look readable (object properties in [])
            if (!!newURL && !!oldURL && decodeURI(newURL) === decodeURI(oldURL)) return;

            this.calcIsFiltersPresent();
        },
        calcIsFiltersPresent() {
            const allFilters = getHashParams() || {};

            this.isClearFiltersDisabled = !(
                allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS] ||
                allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS]?.some(
                    (filter) => filter.name !== PART_DESCRIPTION_ATTRIBUTE_NAME,
                )
            );
        },
        firstDataRendered() {
            // Adds download icons on the lower and upper mount code columns.
            const columns = [COLUMN_NAMES.UPPER_MOUNT_CODE, COLUMN_NAMES.LOWER_MOUNT_CODE];
            const mountCodeTablePath = document.querySelector('[' + MOUNT_CODE_TABLE_PATH_LABEL + ']')?.getAttribute(MOUNT_CODE_TABLE_PATH_LABEL);
            if (mountCodeTablePath && (this.isRanchoBrand || this.isMonroeBrand)) {
                for (let i = 0; i < columns.length; i++) {
                    const a = Object.assign(document.createElement('a'), {
                        className: 'download-file',
                        href: mountCodeTablePath,
                        title: SPECIFICATION_MOUNT_CODE_HOVER_TEXT,
                        innerHTML: `<i class="fa fa-download" aria-hidden="true"></i>`,
                        download: ''
                    });
                    const colHeaderElement = document.querySelector('[col-id="' + columns[i] + '"]');
                    colHeaderElement.appendChild(a);
                }
            }
        }
    },
    created() {
        window.addEventListener("hashchange", this.handleUrlChange, false);
        this.calcIsFiltersPresent();
        const masterList = this.specifications[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY];
        this.numericFieldsNames = this.getNumericFields(masterList);
    },
    destroyed() {
        window.removeEventListener("hashchange", this.handleUrlChange, false);
    },
    watch: {
        "specifications.part_specification_list.specifications": function (newData) {
            setTimeout(() => this.gridApi?.setRowData(this.prepareTableData(newData)), 0);
        },
        "specifications.specification_attribute_master_list": function (newData) {
            const newColumns = this.prepareColumns(newData);
            setTimeout(() => {
                this.gridApi?.setColumnDefs(newColumns);

                setInitColsWidth({
                    gridApi: this.gridApi,
                    gridColumnApi: this.gridColumnApi,
                });

                const allFilters = getHashParams() || {};
                let attributeFilters = allFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || [];

                const activeFilters = this.gridApi?.getFilterModel();

                Object.keys(activeFilters).forEach((filterKey) => {
                    // to update content of the filters, that we interacted with
                    // except currently open filter
                    if (filterKey !== this.latestFilter) this.gridApi?.destroyFilter(filterKey);
                });

                const updatedFilters = attributeFilters.reduce(
                    (acc, filter) => ({ ...acc, [filter.name + '_' + filter.uom]: { value: filter.value } }),
                    activeFilters,
                );

                this.gridApi?.setFilterModel(updatedFilters); // restores selected filters after destroyFilter
            }, 0);

            if (!this.numericFieldsNames?.length) {
                this.numericFieldsNames = this.getNumericFields(newData);
            }
        },
    },
});
</script>
